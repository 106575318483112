
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const loading = ref<boolean>(false);

    //Create form validation object
    const addEmailForm = Yup.object().shape({
      email: Yup.string()
        .required(t("validators_error.required"))
        .label("Email")
    });

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
    });

    return {
      addEmailForm,
      loading
    };
  },
});
